import { useState, createRef } from 'react'
import { useRouter } from 'next/router'
import { post } from 'services/actions'
import { recebaNovidadesSchema } from 'validations'
import { toast } from 'react-toastify'
import ReCAPTCHA from 'react-google-recaptcha'
import styles from "./receiveNews.module.css";
import dynamic from 'next/dynamic'


const InputMasked = dynamic(() => import('react-input-mask'), {
  ssr: false
})

export default function ReceiveNews({ data }) {
  const [nome, setNome] = useState('')
  const [isValidNome, setIsValidNome] = useState(true)
  const [email, setEmail] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [telefone, setTelefone] = useState('')
  const [estado, setEstado] = useState('')
  const [isValidEstado, setIsValidEstado] = useState(true)
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false)

  const router = useRouter()

  const query = router.query

  const pagina = router.asPath

  const optionsUf = [
    {
      label: 'UF',
      value: 'uf'
    },
    {
      label: 'GO',
      value: 'GO'
    },
    {
      label: 'MG',
      value: 'MG'
    },
    {
      label: 'RJ',
      value: 'RJ'
    },
    {
      label: 'SP',
      value: 'SP'
    }
  ]


  async function handleSubimit(e) {
    e.preventDefault()

    const rdOptions = {
      event_type: "CONVERSION",
      event_family: "CDP",
      payload: {
        conversion_identifier: "Site Realiza",
        name: nome,
        email,
        state: estado,
        personal_phone: telefone,
        traffic_source: query.utm_source,
        traffic_medium: query.utm_medium,
        traffic_campaign: query.utm_campaign,
        legal_bases: [
          {
            category: "communications",
            type: "consent",
            status: "granted"
          }
        ]
      }
    }

    setDisabled(true)
    setLoading(true)

    const validateNome = await recebaNovidadesSchema.nome.isValid({ nome })
    const validateEmail = await recebaNovidadesSchema.email.isValid({ email })
    const validateEstado = await recebaNovidadesSchema.estado.isValid({ estado })

    setIsValidNome(validateNome)
    setIsValidEmail(validateEmail)
    setIsValidEstado(validateEstado)

    if (validateNome & validateEmail & validateEstado) {
      try {
        await post('contatos', {
          nome,
          email,
          telefone,
          pagina: pagina === '/' ? 'Home' : pagina
        })

        await post('sendmail', {
          to: 'sac@realizaconstrutora.com.br',
          nome,
          email,
          telefone,
          estado,
          pagina,
          assunto: 'Receba novidades por e-mail'
        })

        await fetch('/api/rdStationConversion', {
          method: 'POST',
          body: JSON.stringify(rdOptions)
        })

        toast.success('Formulário enviado com sucesso!')

        setNome('')
        setEmail('')
        setTelefone('')
        setEstado('')
        setLoading(false)
        setDisabled(false)
      } catch(error) {
        toast.error('Erro ao enviar formulário!')
        setLoading(false)
        setDisabled(false)
      }
    } else {
      setDisabled(false)
      setLoading(false)
    }
  }

  return (
    <section className={`${styles.container} container-full`}>
      <div className={`${styles.content}`}>
        <div className={styles.contentText}>
            <span>RECEBA CONDIÇÕES ESPECIAIS</span>
        </div>
        <div className={styles.form}>
          <form onSubmit={handleSubimit}>
            <div className={styles.formCol}>

              <div className={`${!isValidNome && styles.spanField} ${styles.inputCont}`}>
                <input
                  name="nome"
                  type="name"
                  placeholder="* Seu nome"
                  value={nome}
                  onChange={e => setNome(e.target.value)}
                />
                <span className={isValidNome && styles.spanDisplayNone}>* Campo obrigatório</span>
              </div>

              <div className={`${!isValidEmail && styles.spanField} ${styles.inputCont}`}>
                <input
                  name="email"
                  placeholder="* Seu e-mail"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <span className={isValidEmail && styles.spanDisplayNone}>* Informe um email válido</span>
              </div>
              <div className={styles.inputCont}>
                <InputMasked
                  name="telefone"
                  placeholder="Telefone"
                  value={telefone}
                  onChange={(e) => setTelefone(e.target.value)}
                  mask="(99) 99999-9999"
                />
              </div>
              <div className={`${!isValidEstado && styles.spanField} ${styles.inputCont}`}>
                <select value={estado} onChange={e => setEstado(e.target.value)}>
                    {
                      optionsUf.map(option => {
                        return (
                          <option key={Math.random()} value={option.value}>{option.label}</option>
                        )
                      })
                    }
                  </select>
                  <span className={isValidEstado && styles.spanDisplayNone}>* UF obrigatório</span>
              </div>
              
                <button className={styles.button} type="submit" disabled={disabled}>
                  {
                    (
                      loading &&
                      isValidNome &&
                      isValidEmail
                    ) ? (
                      <span>. . .</span>
                    ) : (

                      <span>ENVIAR</span>
                    )
                  }
                </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
