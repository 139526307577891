import axios from "axios";
const https = require("https");

const api = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  baseURL: process.env.CMS_URL,
});

export default api;
