import dynamic from "next/dynamic";

const Header = dynamic(() => import("components/AppLayout/Header"), {
  ssr: false,
});
const Footer = dynamic(() => import("components/AppLayout/Footer"), {
  ssr: false,
});

export default function AppLayout({ children, data }) {
  return (
    <>
      <Header data={data.header} />
      {children}
      <Footer data={data.footer} />
    </>
  );
}
