import api from 'services/api'


export async function get(url, params) {
  try {
    const res = await api.get(url, { params })
    return res.data
  } catch (error) {
      throw error.response.status
  }
}

export async function post(url, data) {
  try {
    await api.post(url, data)
  } catch (error) {
      throw error.response.status
  }
}
