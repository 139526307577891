import Markdown from "markdown-to-jsx";
import dynamic from "next/dynamic";
import styles from "./sectionHighlights.module.css";


const HighlightCard = dynamic(() => import("components/HighlightCard"), {
  ssr: false
})

export default function SectionHighlights({ data }) {

  return (
    <section className={`${styles.sectionHighlights} container-full`}>
      <div className={`container-geral flex-column ${styles.bgCard}`}>

          <div className={`grid-cont align-self-center flex-column`}>
          
          <div className={styles.callText}>
            <div className={styles.parallelogramDetail} />
            <h2>
              <Markdown>{data.titulo}</Markdown>
            </h2>
          </div>
          <div className={styles.description}>
            <Markdown>{data.chamada}</Markdown>
          </div>
          <div className={styles.cards}>
            {data.icones.map((item) => {
              return <HighlightCard key={item.id} data={item} />;
            })}
          </div>
        </div>
        <div className={styles.backgroundDetail}>
        </div>
        <footer>
          <div className={styles.parallelogram} />
        </footer>
      </div>
    </section>
  );
}
