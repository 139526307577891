import * as yup from 'yup'


export const recebaNovidadesSchema = {
  nome: yup.object().shape({
    nome: yup.string().required()
  }),
  email: yup.object().shape({
    email: yup.string().email().required()
  }),
  estado: yup.object().shape({
    estado: yup.string().required()
  })
}

export const faleConosco = {
  nome: yup.object().shape({
    nome: yup.string().required()
  }),
  email: yup.object().shape({
    email: yup.string().email().required()
  }),
  telefone: yup.object().shape({
    telefone: yup.string().required()
  }),
  select: yup.object().shape({
    select: yup.string().required()
  }),
  mensagem: yup.object().shape({
    mensagem: yup.string().required()
  })
}


export const denuncias = {
  nome: yup.object().shape({
    nome: yup.string().required()
  }),
  cidade: yup.object().shape({
    cidade: yup.string().required()
  }),
  departamento: yup.object().shape({
    departamento: yup.string().required()
  }),
  mensagem: yup.object().shape({
    mensagem: yup.string().required()
  })
}

export const trabalheConosco = {
  nome: yup.object().shape({
    nome: yup.string().required()
  }),
  email: yup.object().shape({
    email: yup.string().email().required()
  }),
  telefone: yup.object().shape({
    telefone: yup.string().required()
  }),
  select: yup.object().shape({
    select: yup.string().required()
  }),
  estado: yup.object().shape({
    estado: yup.string().required()
  }),
  cidade: yup.object().shape({
    cidade: yup.string().required()
  }),
  areaDeInteresse: yup.object().shape({
    areaDeInteresse: yup.string().required()
  }),
  linkedin: yup.object().shape({
    linkedin: yup.string()
  })
}

export const sejaUmParceiro = {
  levantamento: yup.object().shape({
    levantamento: yup.string().required()
  }),
  diretrizes: yup.object().shape({
    diretrizes: yup.string().required()
  }),
  email: yup.object().shape({
    email: yup.string().email().required()
  }),
  telefone: yup.object().shape({
    telefone: yup.string().required()
  }),
  localizacao: yup.object().shape({
    localizacao: yup.string().required()
  }),
  matricula: yup.object().shape({
    matricula: yup.string().required()
  })
}

export const sejaUmFornecedor = {
  nome: yup.object().shape({
    nome: yup.string().required()
  }),
  email: yup.object().shape({
    email: yup.string().required()
  }),
  telefone: yup.object().shape({
    telefone: yup.string().required()
  }),
  servico: yup.object().shape({
    servico: yup.string().required()
  }),
  regiao: yup.object().shape({
    regiao: yup.string().required()
  })
}

export const detalheImovelForm = {
  nome: yup.object().shape({
    nome: yup.string().required()
  }),
  email: yup.object().shape({
    email: yup.string().email().required()
  }),
  telefone: yup.object().shape({
    telefone: yup.string().required()
  }),
  selected: yup.object().shape({
    selected: yup.string().required()
  })
}
