import React from 'react'
import NextHead from 'next/head'


export default function Head( {data} ) {
  return (
    <NextHead>
      <title>
        {data.seo && data.seo.title && data.seo.title.length > 0
          ? data.seo.title
          : data.titulo
            ? data.titulo
            : ""}
      </title>

      <link
        rel="shortcut icon"
        href="https://realizaconstrutora.com.br/cms/uploads/icon_r_657e24da68.svg"
        type="image/svg"
      />

      <meta
        name="description"
        content={
          data.seo && data.seo.description && data.seo.description.length > 0
            ? data.seo.description
            : data.descricao
              ? data.descricao
              : ""
        }
      />
      <meta
        property="og:title"
        content={
          data.seo && data.seo.title && data.seo.title.length > 0
            ? data.seo.title
            : data.titulo
              ? data.titulo
              : ""
        }
      />
      <meta
        property="og:description"
        content={
          data.seo && data.seo.description && data.seo.description.length > 0
            ? data.seo.description
            : data.descricao
              ? data.descricao
              : ""
        }
      />
      <meta
        property="og:image"
        content={
          data.seo && data.seo.image
            ? process.env.CMS_URL + data.seo.image.url
            : data.imagem
              ? process.env.CMS_URL + data.imagem
              : ""
        }
      />
      <meta property="og:url" content={process.env.APP_URL} />
      <meta name="twitter:card" content="summary_large_image" />
    </NextHead>
  )
}
